module.exports = {
	initImportCompanies: initImportCompanies
}

var $ = require('jquery'),
	_ = require('underscore'),
    utils = require('./utils');

function initImportCompanies() {
    console.log('INIT IMPORT COMPANIES');
    var $form = $('form#importCompaniesForm'),
    	$spinnerContainer = $('#spinnerContainer');
	$form.on('click', '#submit-id-import', function(e) {
        utils.showAjaxSpinner($spinnerContainer, true);
	    $(this).attr('disabled', 'disabled');
	    $form.submit();
	});

}
