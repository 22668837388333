module.exports = {
    initStatistics: initStatistics,
    initHenAge: initHenAge,
    initVolumes: initVolumes,
    initCharts: initCharts
}

var $ = require('jquery'),
	_ = require('underscore'),
	moment = require('moment'),
    select2 = require('select2'),
    utils = require('./utils'),
    Chart = require('chart.js');

function initStatistics() {
    console.log('INIT STATISTICS');
    $('#id_production_methods, #id_producers').select2();
    utils.initDatePicker('div_id_from_date');
    utils.initDatePicker('div_id_to_date');
    bindRemoveRow();
}

function initHenAge() {
    console.log('INIT HEN AGE');
    $('#id_producer, #id_coops').select2();
    var $tgtContainer = $("#filterFormContainer");
    bindProducerChange($tgtContainer);
}

function initVolumes() {
    console.log('INIT VOLUMES');
    $('#id_production_methods, #id_producers').select2();
    utils.initDatePicker('div_id_from_date');
    utils.initDatePicker('div_id_to_date');
}

function initCharts(js_batches_data) {
    _.each(js_batches_data, function(batch_data) {
        var ctx = batch_data.id;
        var myChart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: batch_data.labels,
                datasets: [{
                    label: 'Eggs kg',
                    data: batch_data.data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1,
                }]
            }
        });
    });
}

function bindProducerChange($tgtContainer) {
    
    $tgtContainer.on('change', '#id_producer', function() {
        var url = $tgtContainer.data('coops-url'),
            producerId = $(this).val();

        var ajaxReq = $.ajax({
            dataType: 'html',
            type: 'GET',
            data: {
              'producer': producerId
            },
            url: url
        });
        ajaxReq.done(function ( data, textStatus, jqXHR ) {
            console.log(data);
            $('#id_coops').html(data);
        });

    });

}

function bindRemoveRow() {
    $('.remove-row').click(function(e) {
        var $row = $(this).closest('tr'),
            $table = $(this).closest('table');
        $row.fadeOut(300, function() {
            $(this).remove();
            countTableTotals($table);
        });
    });
}

function countTableTotals($table) {
    var $countNums = $table.find('.count-num');
    var sum = 0;
    $countNums.each(function() {
        console.log($(this).val());
        sum += +$(this).val();
    });
    $table.find(".total-count").text(sum);

}
