module.exports = {
    initProducers:initProducers, 
    initProducerForm: initProducerForm
}

var $ = require('jquery'),
	_ = require('underscore'),
	moment = require('moment'),
    utils = require('./utils');

function initProducers() {
    console.log('INIT PRODUCERS');
    bindFilterFormChanged();
    getProducers();
}

function initProducerForm() {
    console.log('INIT PRODUCER FORM');
    utils.bindAddForm('#addCoop', 'id_coop_set', $('#formsetContainer'));
    utils.bindRemoveForm('.remove-coop');
    //$('#id_industries, #id_special_categories', $('#companyForm')).select2();
}

function getProducers(url, formData) {
    var $tgtContainer = $('#producerList'),
        $totalCountContainer = $('#totalItemsFound');
    if(_.isUndefined(url)) url = $tgtContainer.data('url');
    utils.getItems($tgtContainer, url, formData, getProducers, $totalCountContainer);
}

function bindFilterFormChanged() {
    var $form = $('#producerFilterForm');
    $form.on('submit', function(e) {
        e.preventDefault();
    });
    $form.on('change', '#id_search_term', function() {
        var formData = $form.serializeArray(),
            url = $form.attr('action');
        getProducers(url, formData);
    });
}
