module.exports = {
    initSalmonellaSampleForm: initSalmonellaSampleForm,
    initNewSalmonellaSampleForm: initNewSalmonellaSampleForm,
    initSamples: initSamples
}

var $ = require('jquery'),
	_ = require('underscore'),
	moment = require('moment'),
    select2 = require('select2'),
    utils = require('./utils');

function initSalmonellaSampleForm() {
    console.log('INIT SAMPLE FORM');
    utils.initDatePicker('div_id_sampling_date');
}

function initNewSalmonellaSampleForm() {
    console.log('INIT NEW SAMPLE FORM');
    $('#id_batch, #id_producer', $('#newSalmonellaSampleForm')).select2();
    utils.initDatePicker('div_id_sampling_date');
    bindProducerChanged();
}

function bindProducerChanged() {
    $("#id_producer").change(function () {
        var url = $('#newSalmonellaSampleFormContainer').data('batch-options-url'),
            producerId = $(this).val();
        url += producerId;
        var ajaxReq = $.ajax({
            dataType : 'html',
            type : 'GET',
            url : url
        });
        ajaxReq.done(function ( data, textStatus, jqXHR ) {
            $("#id_batch").html(data);
        });

    });
}

function initSamples() {
    console.log('INIT SAMPLES');
    $('#id_producers', $('#samplesFilterForm')).select2({
        width: "100%",
        placeholder: django.gettext('filter by producers'),
    });
    bindFilterFormChanged();
    getSamples();
}

function getSamples(url, formData) {
    var $tgtContainer = $('#samplesList'),
        $totalCountContainer = $('#totalItemsFound');
    if(_.isUndefined(url)) url = $tgtContainer.data('url');
    utils.getItems($tgtContainer, url, formData, getSamples, $totalCountContainer);
}

function bindFilterFormChanged() {
    var $form = $('#samplesFilterForm');
    $form.on('submit', function(e) {
        e.preventDefault();
    });
    $form.on('change', '#id_producers', function() {
        var formData = $form.serializeArray();
        getSamples(undefined, formData);
    });
}
