var $ = require('jquery'),
    select2 = require('select2'),
	csrf = require('./lib/helpers/csrf');

// init helpers
csrf();  // Django CSRF

/**
 * App modules
 */
var utils = require('./lib/utils'),
	producers = require('./lib/producers'),
	batches = require('./lib/batches'),
	statistics = require('./lib/statistics'),
	sampling = require('./lib/sampling'),
	manage = require('./lib/manage');

/**
 * Kieku public app
 */
var App = {
	utils: utils,
	producers: producers,
    batches: batches,
    statistics: statistics,
    sampling: sampling,
    manage: manage
};

window.App = App;
window.jQuery = window.$ = $;
select2(window, $);
require('bootstrap');
